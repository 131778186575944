import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import PrimaryButton from "../../Components/atoms/Primary/PrimaryButton";
import { Images } from "../../helpers/Images";

const AboutTemplate = () => {
  return (
    <>
      <Box
        sx={{
          width: "100%",
          padding: {
            xs: "30px 20px",
            sm: "30px 60px",
            lg: "30px 120px",
            xl: "50px 120px",
          },
          background: `linear-gradient(80deg, #1a3978dd,#007effcc,#007effcc, #1a3978dd), #000000cc`,
          // background: `url(${Images.HomeBack})`,
          // backgroundSize: "cover",
          // backgroundPosition: "center",
          // backgroundRepeat: "no-repeat",
          minHeight: "380px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          boxShadow: "0px 0px 30px 0px rgba(0,0,0,0.15) inset",
        }}
      >
        <Typography
          variant={"h1"}
          sx={{
            textAlign: "center",
            fontSize: { xs: "40px", sm: "45px" },
            fontWeight: "500",
            color: "primary.light",
            marginBottom: "20px",
          }}
        >
          Nice To Meet You!!
        </Typography>
        <Typography
          variant={"p"}
          sx={{
            textAlign: "center",
            fontWeight: "500",
            fontSize: { xs: "15px", sm: "20px" },
            color: "primary.light",
          }}
        >
          {/* At Skill Code, we're pioneers in education, reshaping learning
          paradigms to cultivate a generation of adaptable thinkers. Through our
          dedication to STEAM education (Science, Technology, Robotics,
          Engineering, Arts, and Mathematics), we empower learners with the
          multifaceted skills needed to thrive in the dynamic landscape of the
          21st century. By fostering creativity, critical thinking, and
          technical expertise, we're laying the groundwork for a brighter future
          where innovation knows no bounds. Together, we're driving positive
          change, igniting a passion for lifelong learning, and equipping
          individuals with the tools to shape their world.  */}
          At Skill Code, we're a leading company in the field of STEAM
          education. We provide integrated services to schools that include full
          teaching, preparing laboratories, and supervising teaching operations.
          We seek to convert schools to operate on the STEAM system and provide
          accredited curricula and various entertainment and educational
          programs.
        </Typography>

        {/* <div style={{ display: "flex", justifyContent: "center" }}>
        <PrimaryButton
          classBtn={"center"}
          text={"Get Your Free Consultation Now"}
          style={{
            textTransform: "capitalize",
            borderRadius: "20px",
            color: "var(--secondary)",
            backgroundColor: "white",
            border: "2px solid var(--secondary)",
            height: "40px",
            padding: "0 25px",
            width: "fit-content",
            marginTop: "25px",
          }}
          // click={() => navigate(`/dashboard/classes/${class_id}`)}
        />
      </div> */}
      </Box>
      <Box
        sx={{
          width: "100%",
          padding: { xs: "50px 20px", sm: "50px 60px", lg: "50px 120px" },
          maxWidth: "1500px",
          margin: "auto",
        }}
      >
        <Typography
          variant={"h2"}
          sx={{
            textAlign: "center",
            fontSize: "40px",
            fontWeight: "600",
            color: "primary.main",
            marginTop: "30px",
          }}
        >
          Our Vision
        </Typography>
        <Typography
          sx={{
            textAlign: "center",
            color: "primary.darker",
            width: "100%",
            maxWidth: "1000px",
            margin: "auto",
            marginTop: "10px",
            marginBottom: "50px",
            fontSize: "20px",
          }}
        >
          We aspire to empower students by providing innovative education in
          science, technology, engineering, arts and mathematics (STEAM), which
          fosters creativity and critical thinking and prepares them for a
          sustainable and prosperous future.
        </Typography>
        <Typography
          variant={"h2"}
          sx={{
            textAlign: "center",
            fontSize: "40px",
            fontWeight: "600",
            color: "primary.main",
            marginTop: "30px",
          }}
        >
          Our Mission
        </Typography>
        <Typography
          sx={{
            textAlign: "center",
            color: "primary.darker",
            width: "100%",
            maxWidth: "1000px",
            margin: "auto",
            marginTop: "10px",
            marginBottom: "50px",
            fontSize: "20px",
          }}
        >
          We aim to provide a comprehensive educational environment that
          supports schools, teachers, and students in achieving their full
          potential through advanced educational programs, modern laboratory
          equipment, and ongoing support.
          <img
            alt="steam"
            src={Images.stream}
            style={{
              width: "100%",
              maxWidth: "600px",
              margin: "auto",
              marginTop: "40px",
            }}
          />
        </Typography>
      </Box>
    </>
  );
};

export default AboutTemplate;
