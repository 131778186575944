import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  Typography,
} from "@mui/material";
import React, { useRef, useState } from "react";
import { Images } from "../../helpers/Images";
import { useNavigate } from "react-router-dom";
import TextFieldInput from "../../Components/atoms/TextField/TextFieldInput";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { BaseURL, StudentURL } from "../../Hooks/URL";
import { Fetch } from "../../Hooks/Fetch";
import "./Parent.css";
import { SyncLoader } from "react-spinners";
import ClassCard from "../../Components/molecules/Cards/ClassCard/ClassCard";

const ParentTemplate = () => {
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(0);
  const [StudentData, setStudentData] = useState({});

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getStudent = () => {
    if (StudentData?.national_id != search)
      Fetch(`${StudentURL}/${search}`, setStudentData, setLoading);
  };

  return (
    <>
      <Box
        sx={{
          width: "100%",
          padding: {
            xs: "30px 20px",
            sm: "30px 60px",
            lg: "30px 120px",
            xl: "50px 120px",
          },
          background: `linear-gradient(80deg, #1a3978dd,#007effcc,#007effcc, #1a3978dd), #000000cc`,
          // background: `url(${Images.HomeBack})`,
          // backgroundSize: "cover",
          // backgroundPosition: "center",
          // backgroundRepeat: "no-repeat",
          minHeight: "380px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          boxShadow: "0px 0px 30px 0px rgba(0,0,0,0.15) inset",
        }}
      >
        <Typography
          variant={"h1"}
          sx={{
            textAlign: "center",
            fontSize: { xs: "40px", sm: "45px" },
            fontWeight: "500",
            color: "primary.light",
            marginBottom: "20px",
          }}
        >
          Welcome To Parental Platform
        </Typography>
        <Typography
          variant={"p"}
          sx={{
            textAlign: "center",
            fontWeight: "500",
            fontSize: { xs: "15px", sm: "20px" },
            color: "primary.light",
          }}
        >
          Dear Parents, introducing our dedicated parental platform, designed to
          seamlessly integrate you into your child's educational journey. By
          bridging the gap between home and school, we empower you to play an
          active role in nurturing your child's growth and success. Join us in
          revolutionizing your child's learning experience together.
        </Typography>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <TextFieldInput
            name="search"
            type="text"
            placeholder={"Enter your son's national number"}
            className="Input SearchBar"
            searchIcon
            change={(e) => setSearch(e.target.value)}
            searchCloseAction={() => setSearch("")}
            searchAction={() => getStudent()}
            keyDown={(e) => {
              if (e.keyCode == 13) getStudent();
            }}
            newValue={search}
          />
        </div>
      </Box>
      <Box
        sx={{
          width: "100%",
          padding: { xs: "50px 20px", sm: "50px 60px", lg: "50px 120px" },
          maxWidth: "1500px",
          margin: "auto",
        }}
      >
        {loading ? (
          <div className="loadingSmallBox">
            <SyncLoader size={16} color="#002369" />
          </div>
        ) : Object.keys(StudentData)?.length ? (
          <>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Student Details" />
              <Tab label="Classes" />
            </Tabs>
            <div hidden={value !== 0}>
              {value === 0 && (
                <Box
                  sx={{
                    p: 3,
                    display: "flex",
                    flexDirection: { xs: "column", md: "row" },
                    marginBottom: "20px",
                    alignItems: "center",
                  }}
                >
                  <img
                    alt="photo"
                    className="studentPhoto"
                    src={
                      StudentData?.photo
                        ? `${BaseURL}/images/${StudentData?.photo}`
                        : Images.student
                    }
                  />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      marginLeft: { xs: "0", md: "20px" },
                      alignItems: { xs: "flex-start" },
                    }}
                  >
                    <Typography
                      variant={"p"}
                      sx={{
                        fontWeight: "500",
                        fontSize: { xs: "15px", sm: "18px" },
                        color: "primary.darker",
                        marginBottom: "5px",
                      }}
                    >
                      Name: {StudentData?.name}
                    </Typography>
                    <Typography
                      variant={"p"}
                      sx={{
                        fontWeight: "500",
                        fontSize: { xs: "15px", sm: "18px" },
                        color: "primary.darker",
                        marginBottom: "5px",
                      }}
                    >
                      Gender: {StudentData?.gender == 1 ? "Male" : "Female"}
                    </Typography>
                    <Typography
                      variant={"p"}
                      sx={{
                        fontWeight: "500",
                        fontSize: { xs: "15px", sm: "18px" },
                        color: "primary.darker",
                        marginBottom: "5px",
                      }}
                    >
                      Parent Phone Number: {StudentData?.parent_phone}
                    </Typography>
                    <Typography
                      variant={"p"}
                      sx={{
                        fontWeight: "500",
                        fontSize: { xs: "15px", sm: "18px" },
                        color: "primary.darker",
                        marginBottom: "5px",
                      }}
                    >
                      National ID: {StudentData?.national_id}
                    </Typography>
                    <Typography
                      variant={"p"}
                      sx={{
                        fontWeight: "500",
                        fontSize: { xs: "15px", sm: "18px" },
                        color: "primary.darker",
                        marginBottom: "5px",
                      }}
                    >
                      Age: {StudentData?.age}
                    </Typography>
                    <Typography
                      variant={"p"}
                      sx={{
                        fontWeight: "500",
                        fontSize: { xs: "15px", sm: "18px" },
                        color: "primary.darker",
                        marginBottom: "5px",
                      }}
                    >
                      School: {StudentData?.school_name}
                    </Typography>
                  </Box>
                </Box>
              )}
            </div>
            <div hidden={value !== 1} style={{ marginTop: "20px" }}>
              {value === 1 && (
                <>
                  {StudentData?.classes?.map((Class) => (
                    <ClassCard StudentData={StudentData} Class={Class} />
                  ))}
                </>
              )}
            </div>
          </>
        ) : (
          <>
            <Typography
              variant={"p"}
              sx={{
                textAlign: "center",
                fontWeight: "500",
                fontSize: { xs: "15px", sm: "18px" },
                color: "primary.darker",
                margin: "20px 0px 10px",
                display: "block",
              }}
            >
              Please Enter a valid National Id For a student in Skill Code
            </Typography>
            <Typography
              variant={"p"}
              sx={{
                textAlign: "center",
                fontWeight: "500",
                fontSize: { xs: "13px", sm: "15px" },
                color: "primary.darker",
                display: "block",
                margin: "0px 0px 20px",
              }}
            >
              If your son's national id is not found, please contact your
              school..
            </Typography>
          </>
        )}
      </Box>
    </>
  );
};

export default ParentTemplate;
