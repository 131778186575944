import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  MenuItem,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { useEffect, useRef, useState } from "react";
import { Images } from "../../../../helpers/Images";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import PrimaryButton from "../../../../Components/atoms/Primary/PrimaryButton";
import Groups2Icon from "@mui/icons-material/Groups2";
import DescriptionIcon from "@mui/icons-material/Description";
import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";
import DevicesIcon from "@mui/icons-material/Devices";
import EventIcon from "@mui/icons-material/Event";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import InsightsIcon from "@mui/icons-material/Insights";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import ServiceSection from "../../../../Components/molecules/Cards/ServiceSection";
import SchoolIcon from "@mui/icons-material/School";
import TodayIcon from "@mui/icons-material/Today";
import CampaignIcon from "@mui/icons-material/Campaign";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import TextArea from "../../../../Components/atoms/TextArea/TextArea";
import TextFieldInput from "../../../../Components/atoms/TextField/TextFieldInput";
import { useForm } from "@formspree/react";
import { toast } from "react-toastify";

const CompetitionsTemplate = () => {
  const [state, handleSubmit] = useForm("mrbzqzyp");
  const navigate = useNavigate();

  useEffect(() => {
    if (state.succeeded) {
      toast.success(
        "Thank you for joining us! We  will get back to you as soon as possible.",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          theme: "colored",
          style: { backgroundColor: "#002369" },
        }
      );
    }
  }, [state]);
  return (
    <>
      <Box
        sx={{
          width: "100%",
          padding: {
            xs: "30px 20px",
            sm: "30px 60px",
            lg: "30px 120px",
            xl: "50px 120px",
          },
          background: `linear-gradient(80deg, #1a3978dd,#007effcc,#007effcc, #1a3978dd), #000000cc`,
          // background: `url(${Images.HomeBack})`,
          // backgroundSize: "cover",
          // backgroundPosition: "center",
          // backgroundRepeat: "no-repeat",
          minHeight: "380px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          boxShadow: "0px 0px 30px 0px rgba(0,0,0,0.15) inset",
        }}
      >
        <Typography
          variant={"h1"}
          sx={{
            textAlign: "center",
            fontSize: { xs: "32px", sm: "45px" },
            fontWeight: "500",
            color: "primary.light",
            marginBottom: "20px",
          }}
        >
          Get ready for an exciting journey into the world of technology and
          innovation!
        </Typography>
        <Typography
          variant={"p"}
          sx={{
            textAlign: "center",
            fontWeight: "500",
            fontSize: { xs: "15px", sm: "20px" },
            color: "primary.light",
          }}
        >
          Skill Challenge, powered by Skill Code, is your gateway to the realms
          of programming, robotics, and artificial intelligence. This program is
          designed to develop students' skills and prepare them to compete in
          local and international competitions in these fields. It also fosters
          a culture of innovation and entrepreneurship by providing students
          with the tools and support to develop groundbreaking projects.
        </Typography>

        <div style={{ display: "flex", justifyContent: "center" }}>
          <PrimaryButton
            classBtn={"center"}
            text={"Join Skill Code"}
            style={{
              textTransform: "capitalize",
              borderRadius: "20px",
              color: "var(--secondary)",
              backgroundColor: "white",
              border: "2px solid var(--secondary)",
              height: "40px",
              padding: "0 25px",
              width: "fit-content",
              margin: "25px",
            }}
            click={() => navigate(`/contact`)}
          />
        </div>
      </Box>
      <Box
        sx={{
          width: "100%",
          padding: { xs: "50px 20px", sm: "50px 60px", lg: "50px 120px" },
          maxWidth: "1300px",
          margin: "auto",
        }}
      >
        <Box
          sx={{
            width: "100%",
            maxWidth: {
              xs: "100%",
              sm: "500px",
              margin: "0 0 0 20px",
              float: "right",
            },
            flexShrink: "0",
          }}
        >
          <img
            src={Images?.kids2}
            alt="stemSchools"
            style={{
              width: "100%",
              border: "2px solid var(--primary)",
              marginBottom: "10px",
            }}
          />
        </Box>
        <h3 style={{ fontSize: "25px", fontWeight: "500" }}>
          Skill Code at the Forefront of Educational Innovation:
        </h3>
        <br />
        <Typography
          variant={"p"}
          sx={{
            fontWeight: "200",
            fontSize: { xs: "15px", md: "18px" },
            width: "100%",
          }}
        >
          Skill Code is at the forefront of educational innovation, leading 6
          international competitions in programming and artificial intelligence.
          We provide comprehensive support to participating educational
          institutions, enabling them to deliver innovative learning experiences
          for their students.
        </Typography>
        <br />
        <br />

        <Typography
          variant={"p"}
          sx={{
            fontWeight: "200",
            fontSize: { xs: "15px", md: "18px" },
            width: "100%",
          }}
        >
          Our goal is to democratize access to programming, robotics, and AI
          education. We empower educational institutions with the resources and
          expertise they need to excel in these fields.
        </Typography>
        <br />
        <br />

        <Typography
          variant={"p"}
          sx={{
            fontWeight: "200",
            fontSize: { xs: "15px", md: "18px" },
            width: "100%",
          }}
        >
          Through our international competitions, we provide students with a
          platform to showcase their skills, learn from their peers, and gain
          valuable experience. These competitions foster a spirit of
          collaboration and innovation, inspiring students to push the
          boundaries of what is possible.
        </Typography>
        <br />
        <br />

        <h3 style={{ fontSize: "25px", fontWeight: "500" }}>
          Skill Challenge Includes:
        </h3>
        <br />
        <Typography
          variant={"p"}
          sx={{
            fontWeight: "200",
            fontSize: { xs: "15px", md: "18px" },
            width: "100%",
          }}
        >
          <ul style={{ paddingLeft: "30px" }}>
            <li>
              <span style={{ fontWeight: "400" }}>
                Comprehensive curriculum:
              </span>{" "}
              Aligned with international standards, our curriculum covers a wide
              range of topics, from basic programming concepts to advanced
              robotics.
            </li>
            <li>
              <span style={{ fontWeight: "400" }}>Expert coaching:</span> Our
              team of experienced mentors will guide students through the entire
              project development process.
            </li>
            <li>
              <span style={{ fontWeight: "400" }}>
                State-of-the-art facilities:
              </span>{" "}
              Students will have access to well-equipped labs with the latest
              technology.
            </li>
            <li>
              <span style={{ fontWeight: "400" }}>
                Competitive opportunities:
              </span>{" "}
              We provide a structured plan for students to participate in local
              and international competitions.
            </li>
            <li>
              <span style={{ fontWeight: "400" }}>Incubation support:</span> We
              offer a supportive environment for students to develop their ideas
              and turn them into real-world applications.
            </li>
            <li>
              <span style={{ fontWeight: "400" }}>Parental involvement:</span>{" "}
              Parents can track their child's progress through our mobile app
              and stay updated on their achievements.
            </li>
            <li>
              <span style={{ fontWeight: "400" }}>
                Marketing and promotional materials:
              </span>{" "}
              We provide comprehensive marketing and promotional materials to
              support the program and its events.
            </li>
            <li>
              <span style={{ fontWeight: "400" }}>Full supervision:</span> We
              provide ongoing support and guidance to ensure the successful
              implementation of the program.
            </li>
          </ul>
        </Typography>
        <br />
        <br />

        <h3 style={{ fontSize: "25px", fontWeight: "500" }}>In Summary:</h3>
        <br />
        <Typography
          variant={"p"}
          sx={{
            fontWeight: "200",
            fontSize: { xs: "15px", md: "18px" },
            width: "100%",
          }}
        >
          Skill Code plays a vital role in advancing education in programming,
          robotics, and artificial intelligence by:
        </Typography>
        <br />
        <Typography
          variant={"p"}
          sx={{
            fontWeight: "200",
            fontSize: { xs: "15px", md: "18px" },
            width: "100%",
          }}
        >
          <ul style={{ paddingLeft: "30px" }}>
            <li>
              <span style={{ fontWeight: "400" }}>
                Enabling students and educational institutions
              </span>{" "}
              from around the world to participate and compete.
            </li>
            <li>
              <span style={{ fontWeight: "400" }}>
                Equipping teachers and students with the skills and knowledge
              </span>
              needed to succeed in programming and AI.
            </li>
            <li>
              <span style={{ fontWeight: "400" }}>
                Fostering creativity and innovative problem-solving.
              </span>
            </li>
          </ul>
        </Typography>
        <br />
        <br />

        <hr />
        <Box
          sx={{
            width: "100%",
            padding: { xs: "50px 20px", sm: "50px 60px", lg: "50px 120px" },
            maxWidth: "1500px",
            margin: "auto",
          }}
        >
          <Typography
            variant={"h2"}
            sx={{
              textAlign: "center",
              fontSize: { xs: "28px", md: "40px" },
              fontWeight: "600",
              color: "primary.main",
              marginBottom: "20px",
            }}
          >
            Join us and shape the future of technology!
          </Typography>
          <Typography
            sx={{
              textAlign: "center",
              color: "primary.darker",
              fontSize: { xs: "14px", md: "16px" },
              maxWidth: "900px",
              margin: "auto",
              marginTop: "0px",
              marginBottom: "30px",
            }}
          >
            Welcome to Skill Code Consultation form! Whether you're interested
            in joining our team or seeking expert consultation services, you've
            come to the right place. Please fill out the form below with the
            required information, and we'll get back to you as soon as possible.
          </Typography>
          <form onSubmit={handleSubmit}>
            <div style={{ maxWidth: "600px", margin: "0 auto 20px" }}>
              <TextFieldInput
                Required
                name="name"
                type="text"
                placeholder={"Enter your full name.."}
                className="Input"
                label="Full Name"
              />
            </div>
            <div style={{ maxWidth: "600px", margin: "0 auto 20px" }}>
              <TextFieldInput
                Required
                name="email"
                type="text"
                placeholder={"Enter your email.."}
                className="Input"
                label="Email"
              />
            </div>
            <div style={{ maxWidth: "600px", margin: "0 auto 20px" }}>
              <TextFieldInput
                Required
                select
                label="Country"
                placeholder={"Choose your country"}
                name={"country"}
                fullWidth
                className={`Input`}
                variant="outlined"
                SelectProps={{
                  displayEmpty: true,
                }}
              >
                <MenuItem value="" className="" disabled>
                  Choose Country
                </MenuItem>
                <MenuItem key={1} value={"Egypt"}>
                  Egypt
                </MenuItem>
                <MenuItem key={2} value={"Saudi Arabia"}>
                  Saudi Arabia
                </MenuItem>
                <MenuItem key={3} value={"Kuwait "}>
                  Kuwait
                </MenuItem>
              </TextFieldInput>
            </div>
            <div style={{ maxWidth: "600px", margin: "0 auto 10px" }}>
              <TextArea
                Required
                name="text"
                placeholder={"Enter your message.."}
                className="Input"
                label="Message"
              />
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <PrimaryButton
                type="submit"
                classBtn={"center"}
                text={"Submit"}
                disabled={state.submitting}
                style={{
                  textTransform: "capitalize",
                  borderRadius: "20px",
                  color: "var(--secondary)",
                  border: "2px solid var(--secondary)",
                  padding: "5px 30px",
                  width: "fit-content",
                  marginBottom: "5px",
                }}
              />
            </div>
          </form>
        </Box>
      </Box>
      {/* <Box
        id={"questions"}
        sx={{
          width: "100%",
          padding: { xs: "50px 20px", sm: "50px 60px", lg: "50px 120px" },
          maxWidth: "1500px",
          margin: "auto",
        }}
      >
        <Typography
          variant={"h1"}
          sx={{
            textAlign: "center",
            fontSize: { xs: "40px" },
            fontWeight: "500",
            color: "primary.darker",
          }}
        >
          Questions & Answers
        </Typography>
        <Box
          sx={{
            height: "3px",
            maxWidth: { xs: "120px", md: "200px" },
            width: "100%",
            backgroundColor: "primary.main",
            margin: "10px auto 0 auto",
          }}
        />
        <Box id={"questions"} sx={{ marginTop: "50px" }}>
          {[
            {
              question: "How to join ?",
              answer:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.",
            },
            {
              question: "What is the max number of students in a single team ?",
              answer:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.",
            },
            {
              question: "What is the max number of students in a single team ?",
              answer:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.",
            },
            {
              question: "What is the max number of students in a single team ?",
              answer:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.",
            },
          ].map((item) => (
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>{item.question}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{item.answer}</Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </Box> */}
    </>
  );
};

export default CompetitionsTemplate;
