import axios from "axios";
import { toast } from "react-toastify";

export const Fetch = async (url, setNewData, setLoading) => {
  if (url) {
    setLoading(true);
    try {
      const res = await axios(url, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/app.js",
        },
      });
      setNewData(res?.data?.data);
      setLoading(false);
    } catch (err) {
      toast.error(err.response?.data?.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnFocusLoss: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setLoading(false);
    }
  }
};
