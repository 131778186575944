import React, { useEffect } from "react";
import StemSchoolsTemplate from "../../../Templetes/Services/ServicesDetails/SkillCPD/SkillCPD.template";
import StemCampTemplate from "../../../Templetes/Services/ServicesDetails/StemCamp/StemCamp.template";

const StemCampPage = () => {
  useEffect(() => {
    document.title = "Skill Code - STEM Camp";
  }, []);
  return <StemCampTemplate />;
};

export default StemCampPage;
