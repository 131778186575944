import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  MenuItem,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { useEffect, useRef, useState } from "react";
import { Images } from "../../../../helpers/Images";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import PrimaryButton from "../../../../Components/atoms/Primary/PrimaryButton";
import Groups2Icon from "@mui/icons-material/Groups2";
import DescriptionIcon from "@mui/icons-material/Description";
import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";
import DevicesIcon from "@mui/icons-material/Devices";
import EventIcon from "@mui/icons-material/Event";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import InsightsIcon from "@mui/icons-material/Insights";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import ServiceSection from "../../../../Components/molecules/Cards/ServiceSection";
import SchoolIcon from "@mui/icons-material/School";
import TodayIcon from "@mui/icons-material/Today";
import CampaignIcon from "@mui/icons-material/Campaign";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import TextArea from "../../../../Components/atoms/TextArea/TextArea";
import TextFieldInput from "../../../../Components/atoms/TextField/TextFieldInput";
import { useForm } from "@formspree/react";
import { toast } from "react-toastify";

const Services = [];

const StemCampTemplate = () => {
  const [state, handleSubmit] = useForm("mrbzqzyp");
  const navigate = useNavigate();

  useEffect(() => {
    if (state.succeeded) {
      toast.success(
        "Thank you for joining us! We  will get back to you as soon as possible.",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnFocusLoss: false,
          pauseOnHover: true,
          draggable: true,
          theme: "colored",
          style: { backgroundColor: "#002369" },
        }
      );
    }
  }, [state]);
  return (
    <>
      <Box
        sx={{
          width: "100%",
          padding: {
            xs: "30px 20px",
            sm: "30px 60px",
            lg: "30px 120px",
            xl: "50px 120px",
          },
          background: `linear-gradient(80deg, #1a3978dd,#007effcc,#007effcc, #1a3978dd), #000000cc`,
          // background: `url(${Images.HomeBack})`,
          // backgroundSize: "cover",
          // backgroundPosition: "center",
          // backgroundRepeat: "no-repeat",
          minHeight: "380px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          boxShadow: "0px 0px 30px 0px rgba(0,0,0,0.15) inset",
        }}
      >
        <Typography
          variant={"h1"}
          sx={{
            textAlign: "center",
            fontSize: { xs: "32px", sm: "45px" },
            fontWeight: "500",
            color: "primary.light",
            marginBottom: "20px",
          }}
        >
          Shape Your Future with Skill Camp
        </Typography>
        <Typography
          variant={"p"}
          sx={{
            textAlign: "center",
            fontWeight: "500",
            fontSize: { xs: "15px", sm: "20px" },
            color: "primary.light",
          }}
        >
          Skill Camp is designed to equip students with a unique learning
          experience, transforming them from learners into innovators. In this
          intensive camp, students will delve into the worlds of robotics,
          programming, and entrepreneurship, working on practical projects that
          allow them to apply what they've learned.
        </Typography>

        <div style={{ display: "flex", justifyContent: "center" }}>
          <PrimaryButton
            classBtn={"center"}
            text={"Join Skill Code"}
            style={{
              textTransform: "capitalize",
              borderRadius: "20px",
              color: "var(--secondary)",
              backgroundColor: "white",
              border: "2px solid var(--secondary)",
              height: "40px",
              padding: "0 25px",
              width: "fit-content",
              margin: "25px",
            }}
            click={() => navigate(`/contact`)}
          />
        </div>
      </Box>
      <Box
        sx={{
          width: "100%",
          padding: { xs: "50px 20px", sm: "50px 60px", lg: "50px 120px" },
          maxWidth: "1300px",
          margin: "auto",
        }}
      >
        <Box
          sx={{
            width: "100%",
            maxWidth: {
              xs: "100%",
              sm: "500px",
              margin: "0 0 0 20px",
              float: "right",
            },
            flexShrink: "0",
          }}
        >
          <img
            src={Images?.kids2}
            alt="stemSchools"
            style={{
              width: "100%",
              border: "2px solid var(--primary)",
              marginBottom: "10px",
            }}
          />
        </Box>
        <h3 style={{ fontSize: "25px", fontWeight: "500" }}>
          Skill Camp aims to:
        </h3>
        <br />
        <Typography
          variant={"p"}
          sx={{
            fontWeight: "200",
            fontSize: { xs: "15px", md: "18px" },
            width: "100%",
          }}
        >
          <ul style={{ paddingLeft: "30px" }}>
            <li>
              Develop critical thinking, creativity, and innovation skills.
            </li>
            <li>Boost self-confidence and the ability to handle challenges.</li>
            <li>Foster teamwork and collaboration.</li>
            <li>Discover students' talents and interests.</li>
            <li>Prepare a new generation of leaders and innovators.</li>
          </ul>
        </Typography>
        <br />
        <br />
        <h3 style={{ fontSize: "25px", fontWeight: "500" }}>
          Key activities of the camp include:
        </h3>
        <br />
        <Typography
          variant={"p"}
          sx={{
            fontWeight: "200",
            fontSize: { xs: "15px", md: "18px" },
            width: "100%",
          }}
        >
          <ul style={{ paddingLeft: "30px" }}>
            <li>
              <span style={{ fontWeight: "400" }}>Intensive workshops:</span>{" "}
              Covering a variety of skills such as robotics design, app
              programming, and entrepreneurial idea development.
            </li>
            <li>
              <span style={{ fontWeight: "400" }}>Engineering challenges:</span>{" "}
              Engineering challenges: Exciting competitions requiring teamwork
              and creative problem-solving.
            </li>
            <li>
              <span style={{ fontWeight: "400" }}>Innovation exhibition:</span>{" "}
              To showcase student-completed projects.
            </li>
          </ul>
        </Typography>
        <br />
        <br />
        <h3 style={{ fontSize: "25px", fontWeight: "500" }}>
          Benefits for your students:
        </h3>
        <br />
        <Typography
          variant={"p"}
          sx={{
            fontWeight: "200",
            fontSize: { xs: "15px", md: "18px" },
            width: "100%",
          }}
        >
          <ul style={{ paddingLeft: "30px" }}>
            <li>
              Our program is led by experts in programming, robotics, and
              artificial intelligence with extensive experience working with
              students.
            </li>
            <li>
              Students will learn through hands-on activities and engaging
              projects, making learning fun and motivating.
            </li>
            <li>
              Our camp provides a safe and stimulating environment for students
              to explore and learn.
            </li>
            <li>
              Students will receive certificates of participation, as well as
              awards for outstanding projects.
            </li>
          </ul>
        </Typography>
        <br />
        <br />

        <h3 style={{ fontSize: "25px", fontWeight: "500" }}>
          Additional skills students acquire through our camps:
        </h3>
        <br />
        <Typography
          variant={"p"}
          sx={{
            fontWeight: "200",
            fontSize: { xs: "15px", md: "18px" },
            width: "100%",
          }}
        >
          <ul style={{ paddingLeft: "30px" }}>
            <li>
              Students will significantly develop essential 21st-century skills
              such as programming, problem-solving, and critical thinking.
            </li>
            <li>
              Students will gain increased self-confidence and abilities after
              completing successful projects.
            </li>
            <li>
              The camp will encourage your students to continue exploring the
              world of science and technology.
            </li>
          </ul>
        </Typography>
        <br />
        <br />

        <h3 style={{ fontSize: "25px", fontWeight: "500" }}>
          Overall, Skill Camp offers students a unique opportunity to:
        </h3>
        <br />
        <Typography
          variant={"p"}
          sx={{
            fontWeight: "200",
            fontSize: { xs: "15px", md: "18px" },
            width: "100%",
          }}
        >
          <ul style={{ paddingLeft: "30px" }}>
            <li>
              <span style={{ fontWeight: "400" }}>Learn by doing:</span>{" "}
              Students will apply their knowledge through hands-on projects.
            </li>
            <li>
              <span style={{ fontWeight: "400" }}>
                Develop essential skills:
              </span>{" "}
              Students will acquire skills that are valuable in today's world.
            </li>
            <li>
              <span style={{ fontWeight: "400" }}>Be creative:</span> Students
              will be encouraged to think creatively and come up with innovative
              solutions.
            </li>
            <li>
              <span style={{ fontWeight: "400" }}>
                Collaborate with others:
              </span>{" "}
              Students will work together in teams to achieve common goals.
            </li>
            <li>
              <span style={{ fontWeight: "400" }}>Have fun:</span> The camp will
              be a fun and engaging experience for all participants.
            </li>
          </ul>
        </Typography>
        <br />
        <br />
        <hr />
        <Box
          sx={{
            width: "100%",
            padding: { xs: "50px 20px", sm: "50px 60px", lg: "50px 120px" },
            maxWidth: "1500px",
            margin: "auto",
          }}
        >
          <Typography
            variant={"h2"}
            sx={{
              textAlign: "center",
              fontSize: { xs: "28px", md: "40px" },
              fontWeight: "600",
              color: "primary.main",
              marginBottom: "20px",
            }}
          >
            Join us and shape the future of your students!
          </Typography>
          <Typography
            sx={{
              textAlign: "center",
              color: "primary.darker",
              fontSize: { xs: "14px", md: "16px" },
              maxWidth: "900px",
              margin: "auto",
              marginTop: "0px",
              marginBottom: "30px",
            }}
          >
            Welcome to Skill Code Consultation form! Whether you're interested
            in joining our team or seeking expert consultation services, you've
            come to the right place. Please fill out the form below with the
            required information, and we'll get back to you as soon as possible.
          </Typography>
          <form onSubmit={handleSubmit}>
            <div style={{ maxWidth: "600px", margin: "0 auto 20px" }}>
              <TextFieldInput
                Required
                name="name"
                type="text"
                placeholder={"Enter your full name.."}
                className="Input"
                label="Full Name"
              />
            </div>
            <div style={{ maxWidth: "600px", margin: "0 auto 20px" }}>
              <TextFieldInput
                Required
                name="email"
                type="text"
                placeholder={"Enter your email.."}
                className="Input"
                label="Email"
              />
            </div>
            <div style={{ maxWidth: "600px", margin: "0 auto 20px" }}>
              <TextFieldInput
                Required
                select
                label="Country"
                placeholder={"Choose your country"}
                name={"country"}
                fullWidth
                className={`Input`}
                variant="outlined"
                SelectProps={{
                  displayEmpty: true,
                }}
              >
                <MenuItem value="" className="" disabled>
                  Choose Country
                </MenuItem>
                <MenuItem key={1} value={"Egypt"}>
                  Egypt
                </MenuItem>
                <MenuItem key={2} value={"Saudi Arabia"}>
                  Saudi Arabia
                </MenuItem>
                <MenuItem key={3} value={"Kuwait "}>
                  Kuwait
                </MenuItem>
              </TextFieldInput>
            </div>
            <div style={{ maxWidth: "600px", margin: "0 auto 10px" }}>
              <TextArea
                Required
                name="text"
                placeholder={"Enter your message.."}
                className="Input"
                label="Message"
              />
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <PrimaryButton
                type="submit"
                classBtn={"center"}
                text={"Submit"}
                disabled={state.submitting}
                style={{
                  textTransform: "capitalize",
                  borderRadius: "20px",
                  color: "var(--secondary)",
                  border: "2px solid var(--secondary)",
                  padding: "5px 30px",
                  width: "fit-content",
                  marginBottom: "5px",
                }}
              />
            </div>
          </form>
        </Box>
      </Box>
      {/* <Box
        id={"questions"}
        sx={{
          width: "100%",
          padding: { xs: "50px 20px", sm: "50px 60px", lg: "50px 120px" },
          maxWidth: "1500px",
          margin: "auto",
        }}
      >
        <Typography
          variant={"h1"}
          sx={{
            textAlign: "center",
            fontSize: { xs: "40px" },
            fontWeight: "500",
            color: "primary.darker",
          }}
        >
          Questions & Answers
        </Typography>
        <Box
          sx={{
            height: "3px",
            maxWidth: { xs: "120px", md: "200px" },
            width: "100%",
            backgroundColor: "primary.main",
            margin: "10px auto 0 auto",
          }}
        />
        <Box id={"questions"} sx={{ marginTop: "50px" }}>
          {[
            {
              question: "How to join ?",
              answer:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.",
            },
            {
              question: "What is the max number of students in a single team ?",
              answer:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.",
            },
            {
              question: "What is the max number of students in a single team ?",
              answer:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.",
            },
            {
              question: "What is the max number of students in a single team ?",
              answer:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.",
            },
          ].map((item) => (
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>{item.question}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{item.answer}</Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </Box> */}
    </>
  );
};

export default StemCampTemplate;
